<template>
  <empty-message>
    <h2>{{ $t('management.medals:list.empty.title') }}</h2>
  </empty-message>
</template>

<script>
export default {
  name: 'Medals',
  components: {
    EmptyMessage: () => import('@/components/general/EmptyMessage')
  }
}
</script>

<style scoped>

</style>